import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/common/layout"
import { useForm } from "react-hook-form"
import LoadingImage from "../images/loading.svg"
import { navigate } from "gatsby"

const Index = () => {
  const [noResults, setNoResults] = useState(false)
  const [loading, setLoading] = useState(null)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = async formdata => {
    setLoading(true)
    setNoResults(false)
    const res = await fetch(
      `/.netlify/functions/getAuditionResults?phone=${
        formdata.phone
      }&audNum=${formdata.audNum.toUpperCase()}`
    )
    const data = await res.json()
    if (data.student.length > 0) {
      console.log(data.student)
      if(data.student[0].fields.madeTeam === "Yes"){
      const name = data.student[0].fields["first-name"]
      const img = data.student[0].fields.Picture
      const rclasses = data.student[0].fields.required
      const competitive = data.student[0].fields.competitive
      const specials = data.student[0].fields.specials
      const disclaimer = data.student[0].fields.Disclaimer
      navigate(
        `/audition-results?name=${name}&img=${img}&rclasses=${rclasses}&competitive=${competitive}&specials=${specials}&disclaimer=${disclaimer}&madeTeam=true`
      )
      }else if(data.student[0].fields.madeTeam === "No"){
        const fname = data.student[0].fields["first-name"]
        const lname = data.student[0].fields["last-name"]
        const name = `${fname} ${lname}`
        const disclaimer = data.student[0].fields.Disclaimer
        navigate(
          `/audition-results?name=${name}&disclaimer=${disclaimer}&madeTeam=false`
        )
      }
    } else {
      setLoading(false)
      setNoResults(true)
    }
  }

  return (
    <Layout>
      <SEO title="Audition Results" />
      <Container>
        <div className="pt-10">
          <form
            className="flex flex-col  md:flex-col my-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="text-sm text-ascendance pb-1 md:pb-2">
              Please enter the phone number you have registered at the studio
            </p>
            <div className="flex flex-col md:flex-row items-start">
              <label className="flex md:items-center flex-col md:flex-row text-md md:text-lg text-gray-600 w-full md:w-auto">
                Phone Number
                <span>
                  <input
                    type="tel"
                    className="pl-2 mb-2 md:mb-0 md:ml-2 h-12 rounded-md w-full"
                    name="phone"
                    onChange={() => {
                      setNoResults(false)
                    }}
                    maxLength="10"
                    placeholder="7862221111"
                    {...register("phone", { required: true })}
                  />
                </span>
              </label>
              <label className="flex md:items-center flex-col md:flex-row text-md md:text-lg text-gray-600 md:pl-5 w-full md:w-auto">
                Audition Number
                <span>
                  <input
                    type="text"
                    className="pl-2 mb-2 md:mb-0 md:ml-2 h-12 rounded-md w-full"
                    name="audNum"
                    maxLength="3"
                    onChange={() => setNoResults(false)}
                    placeholder="Z3"
                    {...register("audNum", { required: true })}
                  />
                </span>
              </label>

              <button
                className="md:ml-4 bg-ascendance h-12 text-white md:px-6 rounded-md w-full md:w-auto mt-4 md:mt-0"
                type="submit"
              >
                Search
              </button>
            </div>
          </form>
          <h3 className="mt-10 font-bold">LINES:</h3>
          <p>
            In an effort to make our team lines skill oriented, as opposed to
            divided by age, we have assigned dancers to each of our lines based
            on skill level. This season we will have:{" "}
          </p>
          <ul className="ml-5">
            <li className="list-disc">Beginner/Intermediate Level Line </li>
            <li className="list-disc">Advanced Level Line</li>
          </ul>
          <h3 className="mt-4 font-bold">
            SPECIAL NUMBERS SUMMER PRIVATE LESSON REQUIREMENT:
          </h3>
          <p>
            - In order to avoid a sharp decline in training, stamina and
            technique over summer break, special number recipients (solos, duets
            and trios) will be required to take a minimum of 20 technique
            private lessons over the summer. To be completed at the dancer and
            assigned choreographer best available times and dates (always
            working around studio room availability) .It’s only after completing
            this requirement , that dancers will be elegible to begin learning
            their choreography for the new season.
          </p>
          <p>
            Season 8 Exception : Since this is a change that was not announced
            before auditions took place, and in an effort to be considerate of
            previous summer plans, we will be allowing dancers to complete just
            10 Technique private lessons this time around. With that being said,
            beginning next season (Season 9) the requirement will be 20 private
            lessons (no exceptions){" "}
          </p>
          <h3 className="mt-4 font-bold">TECHNIQUE & BALLET LEVELS UPDATE:</h3>
          In an effort to make our classes (Ballet and Technique) skill
          oriented, as opposed to divided by age, we have rearranged our levels
          to the following:
          <ul className="ml-5">
            <li className="list-disc">Intro to Ballet / Intro to Technique</li>
            <li className="list-disc">Ballet 1 / Technique 1 </li>
            <li className="list-disc">Ballet 2 / Technique 2 </li>
            <li className="list-disc">Ballet 3 / Technique 3 </li>
            <li className="list-disc">Ballet 4 / Technique 4 </li>
          </ul>
          <p className="mt-3">
            Also, new this season. Dancers placed in Ballet level 2 will be
            introduced to pre-pointe work during the second half of the year.</p>

            <h3 className="mt-4 font-bold">TECHNIQUE & BALLET PLACEMENT:</h3>
            <p className="mt-3">If your results reflect more than one
            level of Technique and/or Ballet please know that your official
            placement is the lower level class. If your results say to take
            additional classes in a specific Ballet or Technique level, please
            know that this is a requirement, meaning you should plan to attend
            both classes.
          </p>
        </div>

        {loading && <img src={LoadingImage} />}
        {noResults && (
          <p className="text-red-500">Sorry, no results found. Try Again</p>
        )}
      </Container>
    </Layout>
  )
}

export default Index
